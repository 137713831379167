<template>
  <div class="flex flex-col h-screen">
    <NavbarComponent/>
    <main class="mt-20">
      <div class="grid grid-cols-1 md:grid-cols-5 gap-2 p-3">
        <div class="col-span-2 flex">
          <img src="./../../assets/golfigo/golf.png" alt="">
        </div>
        <div class="col-span-3 flex justify-center">
          <div class="flex flex-col md:w-4/5 mx-auto mt-20">
            <h1 class="text-gray-900 text-4xl"> {{ $t(locale.title) }} 🔒</h1>
            <p class="text-gray-400">
              {{ $t(locale.subTitle) }}
            </p>
            <validation-observer ref="loginValidation">
              <b-form
                  class="auth-login-form mt-2 flex flex-col justify-between"
                  @submit.prevent="validationForm"
              >
                <div class="mt-7">
                  <!-- email -->
                  <b-form-group
                      :label="$t('LoginPage.EmailLabel')"
                      label-for="login-email"
                  >
                    <validation-provider
                        #default="{errors}"
                        :name="$t('LoginPage.EmailLabel')"
                        vid="email"
                        rules="required|email"
                    >
                      <b-form-input
                          id="login-email"
                          v-model="userEmail"
                          :state="
                                                    errors.length > 0 ? false : null
                                                "
                          class="p-2"
                          name="login-email"
                          placeholder="sven@exempel.se"
                      />
                      <small class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mt-10 md:mt-10 w-full md:w-1/3 md:mx-auto">
                  <!-- submit buttons -->
                  <button type="submit" :disabled="processing"
                          class="flex w-full px-4 py-1 focus:outline-none bg-theme-sidebar text-white rounded-md">
                                        <span v-if="processing"
                                              class="flex justify-center items-center space-x-2 whitespace-nowrap">
                                            <svg class="animate-spin h-5 w-5 text-white"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span class="text-xl md:text-xl text-white whitespace-nowrap">{{
                                                $t('Message.wait')
                                              }}</span>
                                        </span>
                    <span v-else class="text-xl md:text-xl whitespace-nowrap text-center w-full text-white">
                                            {{ $t('ForgotPasswordPage.Button') }}
                                        </span>
                  </button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </main>
    <FooterComponent/>
  </div>
</template>

<script>
/* eslint-disable global-require */
import NavbarComponent from './components/Navbar.vue'
import FooterComponent from './components/Footer.vue'
import FaqRenderVue from './custom/AccordionFaq.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate';

import {required, email} from '@validations';
import {togglePasswordVisibility} from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'LoginPage',
  components: {
    ValidationProvider,
    ValidationObserver,
    NavbarComponent,
    FooterComponent,
    FaqRenderVue
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      processing: false,
      locale: {
        title: 'ForgotPasswordPage.CardTitle',
        subTitle: 'ForgotPasswordPage.CardSubtitle',
        emailLabel: 'ForgotPasswordPage.EmailLabel',
        btn: 'ForgotPasswordPage.Button',
        backToLogin: 'ForgotPasswordPage.BackToLogin',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
          ? 'EyeIcon'
          : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {

  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        this.processing = true
        if (success) {
          this.$useJwt
              .requestResetPassword({
                email: this.userEmail,
              })
              .then(res => {
                const {data} = res;
                this.processing = false
                this.userEmail = ''
                let msg =
                    localStorage.getItem('lang') == 'sv'
                        ? data.message
                        : data.eng_message;
                this.popup(
                    msg,
                    'success',
                    this.$t('Message.Success')
                );
                let self = this
                setTimeout(function () {
                  self.$router.push({name: 'login'})
                }, 200)
              })
              .catch(err => {
                this.processing = false
                if (err.response) {
                  let msg =
                      localStorage.getItem('lang') == 'sv'
                          ? err.response.data.message
                          : err.response.data.eng_message;
                  this.popup(
                      msg,
                      'danger',
                      this.$t('Message.Failed')
                  );

                  this.$refs.form.setErrors({
                    email: [msg],
                  });
                }
              });
        }
      });
    },
    popup(message, variant, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          text: message,
          title: title,
          icon:
              variant == 'success'
                  ? 'CheckIcon'
                  : 'AlertTriangleIcon',
          variant: variant,
        },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
// .support-gradient {
//     font-style: normal;
//     font-weight: 794;
//     font-size: 40px;
//     line-height: 48px;
//     text-align: center;
//     background: linear-gradient(266.01deg, #0B0875 12.33%, #00D090 113.67%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     background-clip: text;
//     text-fill-color: transparent;
// }
</style>